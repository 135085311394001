import React from "react";
import authToken from "../../graphql/token";
import { useMutation } from "@apollo/react-hooks";
import { ADD_ACTIVITY } from "../../graphql/mutations/activities";

export const useIsAuthenticated = ({ firebaseClient }) => {
  const [auth, setAuth] = React.useState({
    isAuthenticated: false,
    isRequested: false,
    isSuperUser: false
  });
  const [addActivity] = useMutation(ADD_ACTIVITY);
  React.useEffect(() => {
    firebaseClient.auth().onAuthStateChanged(async user => {
      if (user) {
        const newToken = await user.getIdToken();
        if (newToken && newToken !== authToken.get()) {
          authToken.set(newToken);
          addActivity({
            variables: {
              action: "login",
              description: "user logged in dashboard"
            }
          });
        }
        setAuth({
          isAuthenticated: true,
          isRequested: true,
          isSuperUser:
            user.email === "leoalves@movingshortly.com" ||
            user.email === "ryan@movingshortly.com"
        });
      } else {
        setAuth({
          isAuthenticated: false,
          isRequested: true,
          isSuperUser: false
        });
      }
    });
  }, [auth.isRequested]);
  return auth;
};
