import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Paper,
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableHead
} from "@material-ui/core";
import { useQuery } from "@apollo/react-hooks";
import { GET_ACTIONS } from "graphql/queries/superuser/actions";
import { useParams } from "react-router-dom";

const useStyles = makeStyles({
  paper: {
    margin: "1em",
    padding: "1em"
  },
  table: {
    minWidth: 700
  }
});

export default function SuperUser() {
  const classes = useStyles();
  const { id } = useParams();
  const { data } = useQuery(GET_ACTIONS, {
    variables: { organization_id: id }
  });
  if (data && data.activity && data.activity.length > 0) {
    return (
      <Paper className={classes.paper}>
        <Table className={classes.table} aria-label="spanning table">
          <TableHead>
            <TableRow>
              <TableCell align="left">Action</TableCell>
              <TableCell align="left">Description</TableCell>
              <TableCell align="left">Date</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.activity.map(row => (
              <TableRow key={row.id}>
                <TableCell align="left">{row.action}</TableCell>
                <TableCell align="left">{row.description}</TableCell>
                <TableCell align="left">{row.created_at}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>
    );
  }
  return <div>No data available</div>;
}
