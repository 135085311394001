import React, { useState } from "react";
import AddListForm from "components/Forms/Lists";
import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  Grid,
  Typography,
  FormControl,
  FormHelperText,
  InputLabel,
  Input,
  InputAdornment,
  Select,
  MenuItem,
  CircularProgress,
  Stepper,
  Step,
  StepLabel,
  StepContent
} from "@material-ui/core";

import { useQuery, useMutation } from "@apollo/react-hooks";
import { GET_LOCATIONS } from "graphql/queries/location";
import { GET_NUMBERS } from "graphql/queries/numbers";
import { ADD_ALERT } from "graphql/mutations/alert";
import { PURCHASE_NUMBER } from "graphql/mutations/numbers";
import { useHistory } from "react-router-dom";

import SearchAvailableNumber from "./SearchAvailableNumber";
import SelectTrackingNumber from "./SelectTrackingNumber";
import ForwardTo from "./ForwardTo";
import * as phone from "phone";

const useStyles = makeStyles({
  item: {
    padding: "1em",
    width: "500px"
  },
  container: {
    width: "400px"
  },
  formControl: {
    width: "100%",
    marginBottom: "10px"
  }
});

const steps = [
  "Search for available tracking numbers",
  "Select tracking number",
  "Forward tracking number calls to",
  "Confirmation"
];

function getStepContent(step, setNumberState, numberState) {
  switch (step) {
    case 0:
      return (
        <SearchAvailableNumber
          setNumberState={setNumberState}
          numberState={numberState}
        />
      );
    case 1:
      return (
        <SelectTrackingNumber
          setNumberState={setNumberState}
          numberState={numberState}
        />
      );
    case 2:
      return (
        <ForwardTo setNumberState={setNumberState} numberState={numberState} />
      );
    default:
      return (
        <React.Fragment>
          <Typography className={{ margin: "1em 0" }}>
            All calls from {numberState.e164} will be tracked and forward to{" "}
            {numberState.forwardTo}
          </Typography>
        </React.Fragment>
      );
  }
}

const Add = () => {
  const history = useHistory();
  const classes = useStyles();
  const [addAlertMutation] = useMutation(ADD_ALERT);
  const [purchaseNumberMutation] = useMutation(PURCHASE_NUMBER);
  const [loadingPurchaseMutation, setLoadingPurchaseMutation] = useState(false);
  const {
    loading: locationsLoading,
    data: locationsData,
    error: locationsError
  } = useQuery(GET_LOCATIONS);

  const [numberState, setNumberState] = useState({
    city: "",
    state: "",
    e164: null,
    forwardTo: null,
    step: 0
  });

  const handleNext = () => {
    setNumberState({
      ...numberState,
      step: numberState.step + 1
    });
  };

  const handleBack = () => {
    if (numberState.step > 0) {
      setNumberState({
        ...numberState,
        step: numberState.step - 1
      });
    }
  };

  if (locationsLoading) {
    return <CircularProgress color="secondary" />;
  }
  return (
    <Grid container className={classes.container}>
      <Grid item xs={12}>
        <Stepper activeStep={numberState.step} orientation="vertical">
          {steps.map((label, index) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
              <StepContent>
                <div>{getStepContent(index, setNumberState, numberState)}</div>
                <div className={classes.actionsContainer}>
                  {loadingPurchaseMutation ? (
                    <CircularProgress color="secondary" />
                  ) : (
                    <div>
                      <Button
                        disabled={numberState.step === 0}
                        onClick={handleBack}
                        className={classes.button}
                      >
                        Back
                      </Button>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={async () => {
                          if (numberState.step === steps.length - 1) {
                            const formattedForwardTo = phone(
                              numberState.forwardTo,
                              "",
                              true
                            );
                            if (!formattedForwardTo || !formattedForwardTo[0]) {
                              addAlertMutation({
                                variables: {
                                  type: "Error",
                                  title: "Error",
                                  message: "Forward to number is invalid"
                                }
                              });
                            }
                            if (!numberState.e164) {
                              addAlertMutation({
                                variables: {
                                  type: "Error",
                                  title: "Error",
                                  message: "Tracking number was not selected"
                                }
                              });
                            }
                            if (numberState.e164 && formattedForwardTo[0]) {
                              setLoadingPurchaseMutation(true);
                              await purchaseNumberMutation({
                                variables: {
                                  number: numberState.e164,
                                  forwardTo: formattedForwardTo[0]
                                },
                                refetchQueries: [
                                  {
                                    query: GET_NUMBERS
                                  }
                                ]
                              });

                              setLoadingPurchaseMutation(false);
                              history.push("/admin/numbers");
                            }
                          } else {
                            handleNext();
                          }
                        }}
                        className={classes.button}
                      >
                        {numberState.step === steps.length - 1
                          ? "Add tracking number"
                          : "Next"}
                      </Button>
                    </div>
                  )}
                </div>
              </StepContent>
            </Step>
          ))}
        </Stepper>
      </Grid>
    </Grid>
  );
};

export default Add;
