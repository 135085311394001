import React, { useState } from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import Typography from "@material-ui/core/Typography";
import { useQuery } from "@apollo/react-hooks";
import { GET_NUMBER_ACTIVITY } from "graphql/queries/numbers";

import EmptyList from "components/Numbers/EmptyList";

import Drawer from "@material-ui/core/Drawer";
import AddNumberWizard from "components/Numbers/Add";
import NumberActivity from "components/Numbers/Activity";
import ActivityTable from "./ActivityTable";
import Table from "components/Numbers/Table";
import Fab from "@material-ui/core/Fab";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import Paper from "@material-ui/core/Paper";
import { CircularProgress } from "@material-ui/core";

import { Route, Switch } from "react-router-dom";
import { useHistory, useParams } from "react-router-dom";

import Sound from "react-sound";

const useStyles = makeStyles({
  root: {
    padding: "1em"
  }
});

export default function ListsPage() {
  const classes = useStyles();
  const history = useHistory();
  const { id } = useParams();
  const { data, loading, error } = useQuery(GET_NUMBER_ACTIVITY, {
    variables: {
      id
    }
  });

  return (
    <React.Fragment>
      <GridContainer>
        <GridItem xs={12}>
          <Paper className={classes.root}>
            <GridContainer justify={"flex-start"}>
              <GridItem>
                <Fab
                  color="primary"
                  aria-label="back"
                  onClick={() => {
                    history.push("/admin/numbers");
                  }}
                >
                  <ArrowBackIosIcon />
                </Fab>
              </GridItem>
            </GridContainer>
            {data && data.number_activity && data.number_activity.length > 0 && (
              <GridContainer>
                <GridItem xs={12}>
                  <ActivityTable data={data.number_activity} />
                </GridItem>
              </GridContainer>
            )}
            {loading && <CircularProgress />}
            {data &&
              data.number_activity &&
              data.number_activity.length === 0 &&
              !loading &&
              "No activity found"}
          </Paper>
        </GridItem>
      </GridContainer>
    </React.Fragment>
  );
}
