import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Paper,
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableHead,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  Typography,
} from "@material-ui/core";
import { useQuery } from "@apollo/react-hooks";
import { GET_COMMUNICATIONS } from "graphql/queries/superuser/communications";
import { useParams } from "react-router-dom";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

const useStyles = makeStyles({
  paper: {
    margin: "1em",
    padding: "1em",
  },
  table: {
    minWidth: 700,
  },
});

export default function SuperUser() {
  const classes: any = useStyles();
  const { id } = useParams();
  const { data } = useQuery(GET_COMMUNICATIONS, {
    variables: { organization_id: id },
  });
  if (data && data.communication && data.communication.length > 0) {
    return (
      <Paper className={classes.paper}>
        <Table className={classes.table} aria-label="spanning table">
          <TableHead>
            <TableRow>
              <TableCell align="left">Subject</TableCell>
              <TableCell align="left">Date</TableCell>
              <TableCell align="left">Content</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.communication.map((row) => (
              <TableRow key={row.id}>
                <TableCell align="left">{row.subject}</TableCell>
                <TableCell align="left">{row.created_at}</TableCell>
                <TableCell align="left">
                  <ExpansionPanel>
                    <ExpansionPanelSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography className={classes.heading}>
                        Content
                      </Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                      <div dangerouslySetInnerHTML={{ __html: row.content }} />
                    </ExpansionPanelDetails>
                  </ExpansionPanel>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>
    );
  }
  return <div>No data available</div>;
}
