import gql from "graphql-tag";

export const SIGN_UP = gql`
  mutation new_account(
    $organization: String!
    $phoneNumber: String!
    $email: String!
    $password: String!
    $firstName: String!
    $lastName: String!
    $formatted_address: String!
    $latitude: Float!
    $longitude: Float!
    $place_id: String!
    $types: [String]
    $address_components: [JSON]
  ) {
    new_account(
      data: {
        organization: $organization
        phoneNumber: $phoneNumber
        email: $email
        password: $password
        firstName: $firstName
        lastName: $lastName
        formatted_address: $formatted_address
        latitude: $latitude
        longitude: $longitude
        place_id: $place_id
        types: $types
        address_components: $address_components
      }
    )
  }
`;
