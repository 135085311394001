import gql from "graphql-tag";

export const ADD_ALERT = gql`
  mutation AddAlert($title: String!, $type: String!, $message: String!) {
    add_alert(type: $type, title: $title, message: $message) @client
  }
`;

export const DELETE_ALERT = gql`
  mutation DeleteAlert($id: String!) {
    delete_alert(id: $id) @client
  }
`;
