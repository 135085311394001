import gql from "graphql-tag";

export default gql`
  fragment Alert on alerts {
    id
    type
    title
    message
  }
`;
