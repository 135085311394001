import React, { useState } from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import Typography from "@material-ui/core/Typography";
import EmptyList from "components/List/EmptyList";

import Drawer from "@material-ui/core/Drawer";
import AddListForm from "components/List/Add";
import ListTable from "components/List/Table";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import Paper from "@material-ui/core/Paper";
import { Button } from "@material-ui/core";

import { Route } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { useQuery, useMutation } from "@apollo/react-hooks";
import { ADD_ACTIVITY } from "../../graphql/mutations/activities";
import { GET_GENERATED_LIST } from "graphql/queries/lists";
import { useParams } from "react-router-dom";
import ReactTable from "react-table";

const useStyles = makeStyles({
  root: {
    padding: "1em"
  }
});

export default function ListsPage() {
  const classes = useStyles();
  const history = useHistory();
  const { id } = useParams();
  const {
    data: listData,
    loading: loadingList,
    error: errorLoadingList
  } = useQuery(GET_GENERATED_LIST, { variables: { id } });
  const [addActivity] = useMutation(ADD_ACTIVITY);
  return (
    <React.Fragment>
      <GridContainer>
        <GridItem xs={12}>
          <Button
            color="primary"
            onClick={() => {
              history.push("/admin/lists");
            }}
          >
            {"<<< Back"}
          </Button>
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem xs={12}>
          <h3>List Details</h3>
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem xs={12}>
          <Paper className={classes.root}>
            {listData && listData.filter_listing_results && (
              <ReactTable
                defaultPageSize={10}
                showPaginationBottom={true}
                columns={[
                  {
                    Header: "Location",
                    accessor: "location"
                  },
                  {
                    Header: "Number of Properties",
                    accessor: "num_properties"
                  },
                  {
                    Header: "Created At",
                    accessor: "created_at"
                  },
                  {
                    Header: "Download Files",
                    accessor: "links"
                  }
                ]}
                data={listData.filter_listing_results.map(data => {
                  return {
                    location: data.location.address,
                    num_properties: data.listings_total,
                    created_at: data.created_at,
                    links: (
                      <React.Fragment>
                        <p>
                          <a
                            onClick={() => {
                              addActivity({
                                variables: {
                                  action: "download_label",
                                  description: `download generated label https://storage.googleapis.com/movingshortly-prod-files/${
                                    data.id
                                  }-${data.filter.name
                                    .split(" ")
                                    .join("_")}.doc`
                                }
                              });
                              return true;
                            }}
                            href={`https://storage.googleapis.com/movingshortly-prod-files/${
                              data.id
                            }-${data.filter.name.split(" ").join("_")}.doc`}
                            target="_blank"
                          >
                            Labels
                          </a>
                        </p>
                        <p>
                          <a
                            onClick={() => {
                              addActivity({
                                variables: {
                                  action: "download_csv",
                                  description: `download generated csv https://storage.googleapis.com/movingshortly-prod-files/${
                                    data.id
                                  }-${data.filter.name
                                    .split(" ")
                                    .join("_")}.csv`
                                }
                              });
                              return true;
                            }}
                            href={`https://storage.googleapis.com/movingshortly-prod-files/${
                              data.id
                            }-${data.filter.name.split(" ").join("_")}.csv`}
                            target="_blank"
                          >
                            CSV
                          </a>
                        </p>
                        <p>
                          <a
                            onClick={() => {
                              addActivity({
                                variables: {
                                  action: "download_excel",
                                  description: `download generated xlsx https://storage.googleapis.com/movingshortly-prod-files/${
                                    data.id
                                  }-${data.filter.name
                                    .split(" ")
                                    .join("_")}.xlsx`
                                }
                              });
                              return true;
                            }}
                            href={`https://storage.googleapis.com/movingshortly-prod-files/${
                              data.id
                            }-${data.filter.name.split(" ").join("_")}.xlsx`}
                            target="_blank"
                          >
                            Excel
                          </a>
                        </p>
                      </React.Fragment>
                    )
                  };
                })}
              />
            )}
          </Paper>
        </GridItem>
      </GridContainer>
    </React.Fragment>
  );
}
