import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useQuery } from "@apollo/react-hooks";

import {
  Button,
  Grid,
  Typography,
  FormControl,
  FormHelperText,
  InputLabel,
  Input,
  InputAdornment,
  Select,
  MenuItem,
  CircularProgress,
  Stepper,
  Step,
  StepLabel,
  StepContent
} from "@material-ui/core";

const useStyles = makeStyles({
  item: {
    padding: "1em",
    width: "500px"
  },
  container: {
    width: "400px"
  },
  formControl: {
    width: "100%",
    marginBottom: "10px"
  }
});

const SearchAvailableNumber = ({ setNumberState, numberState }) => {
  const classes = useStyles();
  return (
    <React.Fragment>
      <Grid container>
        <Grid item xs={12}>
          <FormControl className={classes.formControl}>
            <InputLabel id="label-state" htmlFor="state">
              State
            </InputLabel>
            <Select
              labelid="label-state"
              id="state"
              value={numberState.state}
              onChange={e =>
                setNumberState({
                  ...numberState,
                  state: e.target.value
                })
              }
            >
              <MenuItem value="AK">Alaska</MenuItem>
              <MenuItem value="AL">Alabama</MenuItem>
              <MenuItem value="AR">Arkansas</MenuItem>
              <MenuItem value="AZ">Arizona</MenuItem>
              <MenuItem value="CA">California</MenuItem>
              <MenuItem value="CO">Colorado</MenuItem>
              <MenuItem value="CT">Connecticut</MenuItem>
              <MenuItem value="DC">District of Columbia</MenuItem>
              <MenuItem value="DE">Delaware</MenuItem>
              <MenuItem value="FL">Florida</MenuItem>
              <MenuItem value="GA">Georgia</MenuItem>
              <MenuItem value="HI">Hawaii</MenuItem>
              <MenuItem value="IA">Iowa</MenuItem>
              <MenuItem value="ID">Idaho</MenuItem>
              <MenuItem value="IL">Illinois</MenuItem>
              <MenuItem value="IN">Indiana</MenuItem>
              <MenuItem value="KS">Kansas</MenuItem>
              <MenuItem value="KY">Kentucky</MenuItem>
              <MenuItem value="LA">Louisiana</MenuItem>
              <MenuItem value="MA">Massachusetts</MenuItem>
              <MenuItem value="MD">Maryland</MenuItem>
              <MenuItem value="ME">Maine</MenuItem>
              <MenuItem value="MI">Michigan</MenuItem>
              <MenuItem value="MN">Minnesota</MenuItem>
              <MenuItem value="MO">Missouri</MenuItem>
              <MenuItem value="MS">Mississippi</MenuItem>
              <MenuItem value="MT">Montana</MenuItem>
              <MenuItem value="NC">North Carolina</MenuItem>
              <MenuItem value="ND">North Dakota</MenuItem>
              <MenuItem value="NE">Nebraska</MenuItem>
              <MenuItem value="NH">New Hampshire</MenuItem>
              <MenuItem value="NJ">New Jersey</MenuItem>
              <MenuItem value="NM">New Mexico</MenuItem>
              <MenuItem value="NV">Nevada</MenuItem>
              <MenuItem value="NY">New York</MenuItem>
              <MenuItem value="OH">Ohio</MenuItem>
              <MenuItem value="OK">Oklahoma</MenuItem>
              <MenuItem value="OR">Oregon</MenuItem>
              <MenuItem value="PA">Pennsylvania</MenuItem>
              <MenuItem value="RI">Rhode Island</MenuItem>
              <MenuItem value="SC">South Carolina</MenuItem>
              <MenuItem value="SD">South Dakota</MenuItem>
              <MenuItem value="TN">Tennessee</MenuItem>
              <MenuItem value="TX">Texas</MenuItem>
              <MenuItem value="UT">Utah</MenuItem>
              <MenuItem value="VA">Virginia</MenuItem>
              <MenuItem value="VT">Vermont</MenuItem>
              <MenuItem value="WA">Washington</MenuItem>
              <MenuItem value="WI">Wisconsin</MenuItem>
              <MenuItem value="WV">West Virginia</MenuItem>
              <MenuItem value="WY">Wyoming</MenuItem>
              <MenuItem value="AB">Alberta</MenuItem>
              <MenuItem value="BC">British Columbia</MenuItem>
              <MenuItem value="MB">Manitoba</MenuItem>
              <MenuItem value="NB">New Brunswick</MenuItem>
              <MenuItem value="NL">Newfoundland and Labrador</MenuItem>
              <MenuItem value="NS">Nova Scotia</MenuItem>
              <MenuItem value="NT">Northwest Territories</MenuItem>
              <MenuItem value="NU">Nunavut</MenuItem>
              <MenuItem value="ON">Ontario</MenuItem>
              <MenuItem value="PE">Prince Edward Island</MenuItem>
              <MenuItem value="QC">Quebec</MenuItem>
              <MenuItem value="SK">Saskatchewan</MenuItem>
              <MenuItem value="YT">Yukon</MenuItem>
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12}>
          <FormControl className={classes.formControl}>
            <InputLabel id="label-city" htmlFor="city">
              City
            </InputLabel>
            <Input
              labelid="label-city"
              id="city"
              value={numberState.city}
              onChange={e =>
                setNumberState({
                  ...numberState,
                  city: e.target.value
                })
              }
            />
          </FormControl>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default SearchAvailableNumber;
