import gql from "graphql-tag";

export const GET_ACTIONS = gql`
  query($organization_id: uuid!) {
    activity(
      where: { organization_id: { _eq: $organization_id } }
      order_by: { created_at: desc }
    ) {
      action
      description
      created_at
    }
  }
`;
