import React from "react";
import Form from "../Form";
import { initialValues, validationSchema } from "./model";
import { FormikHelpers } from "formik";

type SignupFormProps = {
  children: any;
  onSubmit: (values: any, formikActions: FormikHelpers<any>) => void;
};
const SignupForm = ({ children, onSubmit }: SignupFormProps) => {
  return (
    <Form
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {children}
    </Form>
  );
};

export default SignupForm;
