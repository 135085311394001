import gql from 'graphql-tag'
import AlertFragment from '../fragments/alertFragment'

export const GET_ALERTS = gql`
  query GetAlerts {
    alerts @client {
      ...Alert
    }
  }
  ${AlertFragment}
`
