import React, { useState } from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import Typography from "@material-ui/core/Typography";
import { useQuery } from "@apollo/react-hooks";
import { GET_NUMBERS } from "graphql/queries/numbers";

import EmptyList from "components/Numbers/EmptyList";

import Drawer from "@material-ui/core/Drawer";
import AddNumberWizard from "components/Numbers/Add";
import NumberActivity from "components/Numbers/Activity";
import Table from "components/Numbers/Table";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import Paper from "@material-ui/core/Paper";

import { CircularProgress } from "@material-ui/core";

import { Route, Switch } from "react-router-dom";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles({
  root: {
    padding: "1em"
  }
});

export default function ListsPage() {
  const classes = useStyles();
  const history = useHistory();
  const {
    data: numbersData,
    loading: loadingLists,
    error: errorLoadingLists
  } = useQuery(GET_NUMBERS);
  return (
    <React.Fragment>
      <GridContainer>
        <GridItem xs={12}>
          <Switch>
            <Route exact path={["/admin/numbers", "/admin/numbers/add"]}>
              {numbersData &&
              numbersData.number &&
              numbersData.number.length > 0 ? (
                <Paper className={classes.root}>
                  <GridContainer justify={"flex-end"}>
                    <GridItem>
                      <Fab
                        color="primary"
                        aria-label="add"
                        onClick={() => {
                          history.push("/admin/numbers/add");
                        }}
                      >
                        <AddIcon />
                      </Fab>
                    </GridItem>
                  </GridContainer>
                  <Table data={numbersData.number} />
                </Paper>
              ) : loadingLists ? (
                <CircularProgress />
              ) : (
                <EmptyList />
              )}
            </Route>
            <Route path="/admin/numbers/:id">
              <NumberActivity />
            </Route>
          </Switch>
        </GridItem>
      </GridContainer>
      <Route path="/admin/numbers/add">
        <Drawer
          classes={{
            paper: classes.root
          }}
          anchor="right"
          open={true}
          onClose={() => {
            history.push("/admin/numbers");
          }}
        >
          <AddNumberWizard />
        </Drawer>
      </Route>
    </React.Fragment>
  );
}
