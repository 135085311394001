import * as yup from "yup";

export const initialValues = {
  name: "",
  min_price: 0,
  max_price: 0,
  distance: 60,
  frequency: "",
  property_types: [],
  listing_types: [],
  counties: []
};

export const validationSchema = yup.object().shape({
  name: yup.string().required("Name is a required field"),
  min_price: yup
    .number()
    .required()
    .integer(),
  max_price: yup
    .number()
    .required()
    .integer(),
  distance: yup
    .number()
    .required()
    .min(1, "Radius distance needs to be at lease 1 mile")
    .max(60, "Maximum distance is 60 miles"),
  frequency: yup.string().required(),
  property_types: yup.array().required(),
  listing_types: yup.array().required()
});
