import React, { useState } from "react";
import AddListForm from "components/Forms/Lists";
import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  Grid,
  Typography,
  FormControl,
  FormHelperText,
  InputLabel,
  Input,
  InputAdornment,
  Select,
  MenuItem,
  CircularProgress
} from "@material-ui/core";

import { useQuery, useMutation } from "@apollo/react-hooks";
import { GET_ADD_FILTER_DATA } from "graphql/queries/addFilter";
import { GET_LISTS } from "graphql/queries/lists";

import { ADD_FILTER } from "graphql/mutations/filter";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles({
  item: {
    padding: "1em",
    width: "500px"
  },
  container: {
    width: "400px"
  },
  formControl: {
    width: "100%",
    marginBottom: "10px"
  }
});

const generateDistanceOptions = maxDistance => {
  const size = Math.round(maxDistance / 5);
  let res = [];
  for (let x = 0; x < size; x++) {
    res.push((x + 1) * 5);
  }
  return res;
};

const distanceMenuItem = maxDistance => {
  return generateDistanceOptions(maxDistance).map(item => {
    return <MenuItem value={item}>{item}</MenuItem>;
  });
};

const Add = () => {
  const history = useHistory();
  const classes = useStyles();
  const {
    loading: addFilterDataLoading,
    data: addFilterData,
    error: addFilterDataError
  } = useQuery(GET_ADD_FILTER_DATA);
  const [addFilterMutation] = useMutation(ADD_FILTER);
  return (
    <Grid container className={classes.container}>
      <Grid item xs={12}>
        <AddListForm
          onSubmit={async (values, actions) => {
            try {
              actions.setSubmitting(true);
              let variables = {
                data: [
                  {
                    name: values.name,
                    distance: values.distance,
                    location_id: addFilterData.location[0].id,
                    min_price: values.min_price,
                    max_price: values.max_price,
                    property_types: {
                      data: values.property_types.map(id => {
                        return { property_type_id: id };
                      })
                    },
                    listing_types: {
                      data: values.listing_types.map(id => {
                        return { listing_type_id: id };
                      })
                    },
                    frequency_id: values.frequency
                  }
                ]
              };
              if (values.counties && values.counties.length > 0) {
                variables.data[0].filter_us_counties = {
                  data: values.counties.map(item => {
                    return {
                      us_county_id: item
                    };
                  })
                };
              }
              await addFilterMutation({
                variables,
                refetchQueries: [{ query: GET_LISTS }]
              });
              actions.setSubmitting(false);
              history.push("admin/lists");
            } catch (error) {
              console.log(error);
              actions.setSubmitting(false);
            }
          }}
        >
          {formProps => {
            return (
              <React.Fragment>
                <Grid container>
                  <Grid item xs={12}>
                    <Typography variant="h5" gutterBottom>
                      Add New List
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item xs={12}>
                    <FormControl className={classes.formControl}>
                      <InputLabel htmlFor="location">Location</InputLabel>
                      <Input
                        id="location"
                        value={
                          addFilterData &&
                          addFilterData.location &&
                          addFilterData.location[0] &&
                          addFilterData.location[0].address
                            ? addFilterData.location[0].address
                            : ""
                        }
                        disabled
                      />
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item xs={12}>
                    <FormControl className={classes.formControl}>
                      <InputLabel htmlFor="name">List Name</InputLabel>
                      <Input
                        id="name"
                        aria-describedby="name-helper-text"
                        value={formProps.values.name}
                        onChange={formProps.handleChange("name")}
                        onBlur={formProps.handleBlur("name")}
                        error={
                          formProps.touched &&
                          formProps.touched.name &&
                          formProps.errors &&
                          formProps.errors.name
                            ? true
                            : false
                        }
                      />
                      {formProps.touched &&
                      formProps.touched.name &&
                      formProps.errors &&
                      formProps.errors.name ? (
                        <FormHelperText id="name-helper-text" error>
                          {formProps.errors.name}
                        </FormHelperText>
                      ) : (
                        <div />
                      )}
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item xs={12}>
                    <FormControl className={classes.formControl}>
                      <InputLabel id="label-distance" htmlFor="distance">
                        Radius Distance in Miles
                      </InputLabel>

                      <Input
                        id="distance"
                        value={formProps.values.distance}
                        onChange={formProps.handleChange("distance")}
                        onBlur={formProps.handleBlur("distance")}
                        error={
                          formProps.touched &&
                          formProps.touched.distance &&
                          formProps.errors &&
                          formProps.errors.distance
                            ? true
                            : false
                        }
                      />
                      {formProps.touched &&
                      formProps.touched.distance &&
                      formProps.errors &&
                      formProps.errors.distance ? (
                        <FormHelperText id="distance-helper-text" error>
                          {formProps.errors.distance}
                        </FormHelperText>
                      ) : (
                        <div />
                      )}
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item xs={12}>
                    <FormControl className={classes.formControl}>
                      <InputLabel id="label-min_price" htmlFor="min_price">
                        Minimum Property Price
                      </InputLabel>
                      <Input
                        labelid="label-min_price"
                        id="min_price"
                        value={formProps.values.min_price}
                        onChange={formProps.handleChange("min_price")}
                        onBlur={formProps.handleBlur("min_price")}
                        error={
                          formProps.touched &&
                          formProps.touched.min_price &&
                          formProps.errors &&
                          formProps.errors.min_price
                            ? true
                            : false
                        }
                      />
                      {formProps.touched &&
                      formProps.touched.min_price &&
                      formProps.errors &&
                      formProps.errors.min_price ? (
                        <FormHelperText id="min_price-helper-text" error>
                          {formProps.errors.min_price}
                        </FormHelperText>
                      ) : (
                        <div />
                      )}
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item xs={12}>
                    <FormControl className={classes.formControl}>
                      <InputLabel id="label-max_price" htmlFor="max_price">
                        Maximum Property Price (0 for unlimited)
                      </InputLabel>
                      <Input
                        labelid="label-max_price"
                        id="max_price"
                        value={formProps.values.max_price}
                        onChange={formProps.handleChange("max_price")}
                        onBlur={formProps.handleBlur("max_price")}
                        error={
                          formProps.touched &&
                          formProps.touched.max_price &&
                          formProps.errors &&
                          formProps.errors.max_price
                            ? true
                            : false
                        }
                      />

                      {formProps.touched &&
                      formProps.touched.max_price &&
                      formProps.errors &&
                      formProps.errors.max_price ? (
                        <FormHelperText id="max_price-helper-text" error>
                          {formProps.errors.max_price}
                        </FormHelperText>
                      ) : (
                        <div />
                      )}
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item xs={12}>
                    <FormControl className={classes.formControl}>
                      <InputLabel
                        id="label-property_types"
                        htmlFor="property_types"
                      >
                        Property Type
                      </InputLabel>
                      <Select
                        labelid="label-property_types"
                        id="property_types"
                        multiple={true}
                        value={formProps.values.property_types}
                        input={<Input />}
                        onChange={formProps.handleChange("property_types")}
                        onBlur={formProps.handleBlur("property_types")}
                        error={
                          formProps.touched &&
                          formProps.touched.property_types &&
                          formProps.errors &&
                          formProps.errors.property_types
                            ? true
                            : false
                        }
                      >
                        {addFilterData && addFilterData.property_type ? (
                          addFilterData.property_type
                            .filter(
                              item =>
                                item.country ===
                                addFilterData.location[0].country
                            )
                            .map(item => {
                              return (
                                <MenuItem value={item.id}>{item.name}</MenuItem>
                              );
                            })
                        ) : (
                          <div />
                        )}
                      </Select>
                      {formProps.touched &&
                      formProps.touched.property_types &&
                      formProps.errors &&
                      formProps.errors.property_types ? (
                        <FormHelperText id="property_types-helper-text" error>
                          {formProps.errors.property_types}
                        </FormHelperText>
                      ) : (
                        <div />
                      )}
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item xs={12}>
                    <FormControl className={classes.formControl}>
                      <InputLabel
                        id="label-listing_types"
                        htmlFor="listing_types"
                      >
                        Listing Type
                      </InputLabel>
                      <Select
                        labelid="label-listing_types"
                        id="listing_types"
                        input={<Input />}
                        value={formProps.values.listing_types}
                        multiple={true}
                        onChange={formProps.handleChange("listing_types")}
                        onBlur={formProps.handleBlur("listing_types")}
                        error={
                          formProps.touched &&
                          formProps.touched.listing_types &&
                          formProps.errors &&
                          formProps.errors.listing_types
                            ? true
                            : false
                        }
                      >
                        {addFilterData && addFilterData.listing_type ? (
                          addFilterData.listing_type
                            .filter(
                              item =>
                                item.country ===
                                addFilterData.location[0].country
                            )
                            .map(item => {
                              return (
                                <MenuItem value={item.id}>{item.name}</MenuItem>
                              );
                            })
                        ) : (
                          <div />
                        )}
                      </Select>
                      {formProps.touched &&
                      formProps.touched.listing_types &&
                      formProps.errors &&
                      formProps.errors.listing_types ? (
                        <FormHelperText id="listing_types-helper-text" error>
                          {formProps.errors.listing_types}
                        </FormHelperText>
                      ) : (
                        <div />
                      )}
                    </FormControl>
                  </Grid>
                </Grid>
                {addFilterData &&
                  addFilterData.location &&
                  addFilterData.location[0] &&
                  addFilterData.location[0].us_county_to_downloads &&
                  addFilterData.location[0].us_county_to_downloads &&
                  addFilterData.location[0].us_county_to_downloads.length >
                    0 && (
                    <Grid container>
                      <Grid item xs={12}>
                        <FormControl className={classes.formControl}>
                          <InputLabel id="label-counties" htmlFor="counties">
                            Counties (leave it blank for all)
                          </InputLabel>
                          <Select
                            labelid="label-counties"
                            id="counties"
                            input={<Input />}
                            multiple={true}
                            value={formProps.values.counties}
                            onChange={formProps.handleChange("counties")}
                            onBlur={formProps.handleBlur("counties")}
                            error={
                              formProps.touched &&
                              formProps.touched.counties &&
                              formProps.errors &&
                              formProps.errors.counties
                                ? true
                                : false
                            }
                          >
                            {addFilterData.location[0].us_county_to_downloads.map(
                              item => {
                                return (
                                  <MenuItem
                                    key={item.us_county.id}
                                    value={item.us_county.id}
                                  >{`${item.us_county.name} - ${item.us_county.state}`}</MenuItem>
                                );
                              }
                            )}
                          </Select>
                          {formProps.touched &&
                          formProps.touched.counties &&
                          formProps.errors &&
                          formProps.errors.counties ? (
                            <FormHelperText
                              id="listing_types-helper-text"
                              error
                            >
                              {formProps.errors.counties}
                            </FormHelperText>
                          ) : (
                            <div />
                          )}
                        </FormControl>
                      </Grid>
                    </Grid>
                  )}
                <Grid container>
                  <Grid item xs={12}>
                    <FormControl className={classes.formControl}>
                      <InputLabel id="label-frequency" htmlFor="frequency">
                        Frequency the list will be generated
                      </InputLabel>
                      <Select
                        labelid="label-frequency"
                        id="frequency"
                        value={formProps.values.frequency}
                        onChange={formProps.handleChange("frequency")}
                        onBlur={formProps.handleBlur("frequency")}
                        error={
                          formProps.touched &&
                          formProps.touched.frequency &&
                          formProps.errors &&
                          formProps.errors.frequency
                            ? true
                            : false
                        }
                      >
                        {addFilterData && addFilterData.frequency ? (
                          addFilterData.frequency.map(item => {
                            return (
                              <MenuItem value={item.id}>{item.name}</MenuItem>
                            );
                          })
                        ) : (
                          <div />
                        )}
                      </Select>
                      {formProps.touched &&
                      formProps.touched.frequency &&
                      formProps.errors &&
                      formProps.errors.frequency ? (
                        <FormHelperText id="frequency-helper-text" error>
                          {formProps.errors.frequency}
                        </FormHelperText>
                      ) : (
                        <div />
                      )}
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item xs={12}>
                    {formProps.isSubmitting ? (
                      <CircularProgress />
                    ) : (
                      <Button
                        onClick={() => {
                          formProps.submitForm();
                        }}
                      >
                        Save List
                      </Button>
                    )}
                  </Grid>
                </Grid>
              </React.Fragment>
            );
          }}
        </AddListForm>
      </Grid>
    </Grid>
  );
};

export default Add;
