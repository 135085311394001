import { useEffect, useState } from "react";
import { useMutation } from "@apollo/react-hooks";
import { ADD_ALERT } from "graphql/mutations/alert";

export const useAddErrorAlert = () => {
  const [error, setError] = useState(undefined as
    | undefined
    | { type: string; title: string; message: string });
  const [addAlertMutation] = useMutation(ADD_ALERT);
  const addErrorAlert: (error: any) => void = error => {
    if (error.message) {
      setError({
        type: "error",
        title: "Error",
        message: error.message
      });
    }
  };

  useEffect(() => {
    if (error) {
      addAlertMutation({
        variables: {
          type: error.type,
          title: error.title,
          message: error.message
        }
      });
    }
  }, [error]);
  return addErrorAlert;
};
