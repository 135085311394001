import React from "react";
import Form from "../Form";
import { initialValues, validationSchema } from "./model";
import { FormikHelpers } from "formik";

type LoginFormProps = {
  children: any;
  onSubmit: (values: any, formikActions: FormikHelpers<any>) => void;
};
const LoginForm = ({ children, onSubmit }: LoginFormProps) => {
  return (
    <Form
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={async (values, formikActions) => {
        onSubmit(values, formikActions);
      }}
    >
      {children}
    </Form>
  );
};

export default LoginForm;
