import React from "react";
import Alert from "@material-ui/lab/Alert";
import { Grid, Paper, makeStyles, Typography } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  paper: {
    margin: "1em",
    padding: "1em"
  },
  img: {
    width: "100%",
    maxWidth: "500px"
  },
  title: {
    marginBottom: "1em"
  }
}));

const Welcome = () => {
  const classes = useStyles();
  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      alignContent="center"
    >
      <Grid item xs={12}>
        <Paper elevation={3} className={classes.paper}>
          <Typography variant="h4" className={classes.title}>
            Welcome to the Moving Shortly Family!
          </Typography>
          <Typography variant="h5" className={classes.title}>
            We started collecting your data. It takes 24 hours to be available.
          </Typography>
          <p>
            You have taken the first step towards starting to get higher revenue
            producing moving and storage clients.
          </p>

          <p>
            You have a FREE 2 week trial to set up and try out our platform. The
            first thing you want to do is select the type of lists you would
            like to receive. You can watch the video below as a guide to setting
            up your first lists.
          </p>
          <p>
            <a
              href="https://www.movingshortly.com/how_to_setup_list.html"
              target="_blank"
            >
              <img
                src="https://firebasestorage.googleapis.com/v0/b/movingshortly-prod.appspot.com/o/how_to_setup_list_video_thumbnail.png?alt=media&token=82b07c98-a87d-4f12-9eff-723c078e3dc3"
                alt="How to setup your list"
                className={classes.img}
              />
            </a>
          </p>
          <p>
            In addition to setting up your lists, you also will want to set up
            your call tracking numbers, which are included for FREE as part of
            your trial.
          </p>
          <p>
            <a
              href="https://www.movingshortly.com/how_to_setup_tracking_number.html"
              target="_blank"
            >
              <img
                src="https://firebasestorage.googleapis.com/v0/b/movingshortly-prod.appspot.com/o/how_to_setup_numbers_video_thumbnail.png?alt=media&token=af3a785c-d809-48b5-9c6b-f85b560928aa"
                alt="How to setup your tracking number"
                className={classes.img}
              />
            </a>
          </p>
          <p>
            Our goal is not only to provide you with the best lead source for
            your business but also show you the various ways to market to these
            leads. Check your email regularly as we will be sending you tips!{" "}
          </p>
          <p>
            If you have any questions please give us a{" "}
            <a href="mailto: info@movingshortly.com">shout</a>.
          </p>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default Welcome;
