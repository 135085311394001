import gql from "graphql-tag";

export const GET_COMMUNICATIONS = gql`
  query($organization_id: uuid!) {
    communication(
      where: { organization_id: { _eq: $organization_id } }
      order_by: { created_at: desc }
    ) {
      id
      subject
      created_at
      content
    }
  }
`;
