import React from "react";
import ReactTable from "react-table";
import { Link } from "react-router-dom";

const Table = ({ data }: { data: any }) => {
  console.log(data);
  return (
    <ReactTable
      defaultPageSize={5}
      showPaginationBottom={true}
      columns={[
        {
          Header: "Number",
          accessor: "number"
        },
        {
          Header: "forward_to",
          accessor: "forward_to"
        },
        {
          Header: "Calls in last 24 hours",
          accessor: "day"
        },
        {
          Header: "Calls in the last week",
          accessor: "week"
        },
        {
          Header: "Calls in the last month",
          accessor: "month"
        },
        {
          Header: "",
          accessor: "actions"
        }
      ]}
      data={data.map(data => {
        return {
          number: <Link to={`/admin/numbers/${data.id}`}>{data.number}</Link>,
          forward_to: data.forward_to,
          day: data && data.stats && data.stats.day ? data.stats.day : 0,
          week: data && data.stats && data.stats.week ? data.stats.week : 0,
          month: data && data.stats && data.stats.month ? data.stats.month : 0
        };
      })}
    />
  );
};

export default Table;
