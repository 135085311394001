import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useQuery } from "@apollo/react-hooks";
import { SEARCH_AVAILABLE_NUMBERS } from "graphql/queries/numbers";

import {
  Button,
  Grid,
  Typography,
  FormControl,
  FormHelperText,
  InputLabel,
  Input,
  InputAdornment,
  Select,
  MenuItem,
  CircularProgress,
  Stepper,
  Step,
  StepLabel,
  StepContent
} from "@material-ui/core";

const useStyles = makeStyles({
  item: {
    padding: "1em",
    width: "500px"
  },
  container: {
    width: "400px"
  },
  formControl: {
    width: "100%",
    marginBottom: "10px"
  }
});

const SelectTrackingNumber = ({ setNumberState, numberState }) => {
  const classes = useStyles();
  const { data, loading, error } = useQuery(SEARCH_AVAILABLE_NUMBERS, {
    variables: {
      city: numberState.city,
      state: numberState.state
    }
  });
  if (loading) {
    return <CircularProgress color="secondary" />;
  }

  if (
    data &&
    data.search_available_numbers &&
    data.search_available_numbers.length > 0
  ) {
    return (
      <React.Fragment>
        <Grid container>
          {data.search_available_numbers.map(number => {
            return (
              <Grid item xs={6} key={number.e164}>
                <Grid container>
                  <Grid item>
                    <Typography>{number.national_number_formatted}</Typography>
                  </Grid>
                  <Grid item>
                    <Button
                      color="secondary"
                      onClick={() => {
                        setNumberState({
                          ...numberState,
                          step: numberState.step + 1,
                          e164: number.e164
                        });
                      }}
                    >
                      Select Number
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            );
          })}
        </Grid>
      </React.Fragment>
    );
  }

  if (
    data &&
    data.search_available_numbers &&
    data.search_available_numbers.length === 0
  ) {
    return (
      <React.Fragment>
        <Typography>
          0 numbers available in the chosen area. Please go back and try a
          different city
        </Typography>
      </React.Fragment>
    );
  }

  return <Typography>There was an error. Please try again later.</Typography>;
};

export default SelectTrackingNumber;
