import React from "react";
import cx from "classnames";
import { Switch, Route, Redirect } from "react-router-dom";
import firebaseApp from "../services/firebase";

// creates a beautiful scrollbar
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import AdminNavbar from "components/Navbars/AdminNavbar.js";
// import Footer from "components/Footer/Footer.js";
import Sidebar from "components/Sidebar/Sidebar.js";
// import FixedPlugin from "components/FixedPlugin/FixedPlugin.js";

import SuperUserFilters from "components/SuperUser/SuperUserLists";
import SuperUserCommunications from "components/SuperUser/SuperUserCommunications";
import SuperUserActions from "components/SuperUser/SuperUserActions";

import routes from "routes.js";

import styles from "assets/jss/material-dashboard-pro-react/layouts/adminStyle.js";

import { useIsAuthenticated } from "hooks/authentication/useIsAuthenticated";

import ListPage from "views/Pages/ListPage";

let ps: any = undefined;

const useStyles = makeStyles(styles as any);

export default function Dashboard(props) {
  const auth = useIsAuthenticated({
    firebaseClient: firebaseApp
  });

  const { ...rest } = props;
  // states and functions
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [miniActive, setMiniActive] = React.useState(false);
  const [image] = React.useState(require("assets/img/sidebar-2.jpg"));
  const [color] = React.useState("blue");
  const [bgColor] = React.useState("black");
  // const [hasImage, setHasImage] = React.useState(true);
  // const [fixedClasses, setFixedClasses] = React.useState("dropdown");
  const [logo] = React.useState(require("assets/img/logo_dashboard.png"));
  // styles
  const classes = useStyles();
  const mainPanelClasses =
    classes.mainPanel +
    " " +
    cx({
      [classes.mainPanelSidebarMini]: miniActive,
      [classes.mainPanelWithPerfectScrollbar]: true
    });
  // ref for main panel div
  const mainPanel: any = React.createRef();
  React.useEffect(() => {
    if (auth.isAuthenticated) {
      ps = new PerfectScrollbar(mainPanel.current, {
        suppressScrollX: true,
        suppressScrollY: false
      });
      document.body.style.overflow = "hidden";

      window.addEventListener("resize", resizeFunction);
      return function cleanup() {
        ps.destroy();
        window.removeEventListener("resize", resizeFunction);
      };
    }
    return;
    // Specify how to clean up after this effect:
  }, [auth.isAuthenticated]);
  // functions for changeing the states from components
  // const handleImageClick = image => {
  //   setImage(image);
  // };
  // const handleColorClick = color => {
  //   setColor(color);
  // };
  // const handleBgColorClick = bgColor => {
  //   switch (bgColor) {
  //     case "white":
  //       setLogo(require("assets/img/logo.svg"));
  //       break;
  //     default:
  //       setLogo(require("assets/img/logo-white.svg"));
  //       break;
  //   }
  //   setBgColor(bgColor);
  // };
  // const handleFixedClick = () => {
  //   if (fixedClasses === "dropdown") {
  //     setFixedClasses("dropdown show");
  //   } else {
  //     setFixedClasses("dropdown");
  //   }
  // };
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const getActiveRoute = routes => {
    let activeRoute = "Default Brand Text";
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        let collapseActiveRoute = getActiveRoute(routes[i].views);
        if (collapseActiveRoute !== activeRoute) {
          return collapseActiveRoute;
        }
      } else {
        if (
          window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
        ) {
          return routes[i].name;
        }
      }
    }
    return activeRoute;
  };
  const getRoutes = routes => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return getRoutes(prop.views);
      }
      if (prop.layout === "/admin" && !auth.isSuperUser) {
        console.log("return admin route");
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      }
      if (prop.layout === "/superuser" && auth.isSuperUser) {
        console.log("return is super admin route");
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      }
      return null;
    });
  };
  const sidebarMinimize = () => {
    setMiniActive(!miniActive);
  };
  const resizeFunction = () => {
    if (window.innerWidth >= 960) {
      setMobileOpen(false);
    }
  };

  if (auth.isAuthenticated) {
    return (
      <div className={classes.wrapper}>
        <Sidebar
          routes={routes.filter(route => {
            if (auth.isSuperUser) {
              return !route.layout || route.layout === "/superuser";
            }
            return !route.layout || route.layout === "/admin";
          })}
          logoText={"MovingShortly"}
          logo={logo}
          image={image}
          handleDrawerToggle={handleDrawerToggle}
          open={mobileOpen}
          color={color}
          bgColor={bgColor}
          miniActive={miniActive}
          {...rest}
        />
        <div className={mainPanelClasses} ref={mainPanel}>
          <AdminNavbar
            sidebarMinimize={sidebarMinimize.bind(this)}
            miniActive={miniActive}
            brandText={getActiveRoute(routes)}
            handleDrawerToggle={handleDrawerToggle}
            {...rest}
          />
          <div className={classes.map}>
            <Switch>
              <Route path="/admin/lists/view/:id" component={ListPage} />
              {getRoutes(routes)}
              {auth.isSuperUser && (
                <Route
                  path="/superuser/filters/:id"
                  component={SuperUserFilters}
                />
              )}
              {auth.isSuperUser && (
                <Route
                  path="/superuser/communications/:id"
                  component={SuperUserCommunications}
                />
              )}
              {auth.isSuperUser && (
                <Route
                  path="/superuser/actions/:id"
                  component={SuperUserActions}
                />
              )}

              {auth.isSuperUser && <Redirect to="/superuser/dashboard" />}
            </Switch>
          </div>
        </div>
      </div>
    );
  }
  if (auth.isAuthenticated === false && auth.isRequested === true) {
    return <Redirect to="/anonymous" />;
  }
  return <div />;
}
