import React from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";

// @material-ui/icons
import Face from "@material-ui/icons/Face";
import Email from "@material-ui/icons/Email";
// import LockOutline from "@material-ui/icons/LockOutline";

// core components
import { CircularProgress } from "@material-ui/core";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardFooter from "components/Card/CardFooter.js";

import { Link } from "react-router-dom";

import styles from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.js";
import firebase from "services/firebase";
import { useMutation } from "@apollo/react-hooks";
import { GENERATE_CUSTOM_TOKEN } from "graphql/mutations/token";

const useStyles = makeStyles(styles);

export default function LoginPage() {
  const [cardAnimaton, setCardAnimation] = React.useState("cardHidden");
  const [isSubmitting, setIsSubmitting] = React.useState(false);

  const [firebaseId, setFirebaseId] = React.useState(undefined);
  const [token, setToken] = React.useState(undefined);

  const [generateCustomTokenMutation] = useMutation(GENERATE_CUSTOM_TOKEN);

  setTimeout(function() {
    setCardAnimation("");
  }, 700);
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={6} md={6}>
          <Card login className={classes[cardAnimaton]}>
            <CardHeader
              className={`${classes.cardHeader} ${classes.textCenter}`}
              color="rose"
            >
              <h4 className={classes.cardTitle}>Generate Login Token</h4>
            </CardHeader>
            <CardBody>
              <CustomInput
                inputProps={{
                  value: firebaseId,
                  onChange: e => setFirebaseId(e.target.value)
                }}
              />
              <Button
                onClick={() => {
                  generateCustomTokenMutation({
                    variables: {
                      id: firebaseId
                    }
                  });
                }}
              >
                Send email with token
              </Button>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
      <GridContainer justify="center">
        <GridItem xs={12} sm={6} md={6}>
          <Card login className={classes[cardAnimaton]}>
            <CardHeader
              className={`${classes.cardHeader} ${classes.textCenter}`}
              color="rose"
            >
              <h4 className={classes.cardTitle}>Login with token</h4>
            </CardHeader>
            <CardBody>
              <CustomInput
                inputProps={{
                  value: token,
                  onChange: e => setToken(e.target.value)
                }}
              />
              <Button
                onClick={() => {
                  firebase.auth().signInWithCustomToken(token);
                }}
              >
                Login
              </Button>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}
