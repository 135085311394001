import gql from "graphql-tag";

export const GET_ADD_FILTER_DATA = gql`
  query {
    location {
      id
      address
      country
      plan {
        distance_in_miles
      }
      us_county_to_downloads {
        us_county {
          id
          name
          state
        }
      }
    }
    frequency {
      id
      name
    }
    property_type {
      id
      name
      country
    }
    listing_type {
      id
      name
      country
    }
  }
`;
