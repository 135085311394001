import * as firebase from "firebase/app";
import "firebase/analytics";
import "firebase/auth";

// var firebaseConfig = {
//   apiKey: "AIzaSyDmU5X4WvaAyi2lUS9AbfWpQE6gsIj9l78",
//   authDomain: "movingshortly-dev.firebaseapp.com",
//   databaseURL: "https://movingshortly-dev.firebaseio.com",
//   projectId: "movingshortly-dev",
//   storageBucket: "movingshortly-dev.appspot.com",
//   messagingSenderId: "872159371262",
//   appId: "1:872159371262:web:2fa146b22956166ac6aaaf",
//   measurementId: "G-9HX6NB5CFN"
// };

const firebaseConfig = {
  apiKey: "AIzaSyBi6WDBhFlBpMs_upZ4RW-DTo6jM4l4uZY",
  authDomain: "movingshortly-prod.firebaseapp.com",
  databaseURL: "https://movingshortly-prod.firebaseio.com",
  projectId: "movingshortly-prod",
  storageBucket: "movingshortly-prod.appspot.com",
  messagingSenderId: "404721448863",
  appId: "1:404721448863:web:a0a9f3105ba3f82392efaa",
  measurementId: "G-GDVTVM7N47"
};

const firebaseApp = firebase.initializeApp(firebaseConfig);
firebaseApp.analytics();

export default firebaseApp;
