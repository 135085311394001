import { InMemoryCache } from "apollo-cache-inmemory";
import { GET_ALERTS } from "../../queries/alert";
const uuidv4 = require("uuid/v4");

const AlertResolver = {
  add_alert: (
    _: any,
    { message, type, title }: { message: string; type: string; title: string },
    { cache, getCacheKey }: { cache: InMemoryCache; getCacheKey: any }
  ) => {
    const { alerts }: any | [] = cache.readQuery({
      query: GET_ALERTS
    });
    const data = {
      alerts: alerts.concat({
        id: uuidv4(),
        type,
        title,
        message,
        __typename: "alert"
      })
    };
    cache.writeData({ data });
    return null;
  },
  delete_alert: (
    _: any,
    { id }: { id: string },
    { cache, getCacheKey }: { cache: InMemoryCache; getCacheKey: any }
  ) => {
    const { alerts }: any | [] = cache.readQuery({
      query: GET_ALERTS
    });

    const data = {
      alerts: alerts.filter((alert: any) => alert.id !== id)
    };
    cache.writeData({ data });
    return null;
  }
};
export default AlertResolver;
