import React, { useState } from "react";
import ReactTable from "react-table";
import { Button, Chip, CircularProgress } from "@material-ui/core";
// import { useHistory } from "react-router-dom";
import { useMutation } from "@apollo/react-hooks";
import { GET_LISTS } from "graphql/queries/lists";
import { DELETE_FILTER } from "graphql/mutations/filter";

const Table = ({ data, addActivity }: { data: any; addActivity: any }) => {
  //const history = useHistory();
  const [deleteFilterMutation] = useMutation(DELETE_FILTER);
  const [deletingFilter, setDeletingFiter] = useState(false);
  const deleteFilter = async (id) => {
    setDeletingFiter(true);
    await deleteFilterMutation({
      variables: { id },
      refetchQueries: [{ query: GET_LISTS }],
    });
    setDeletingFiter(false);
  };
  return (
    <ReactTable
      defaultPageSize={5}
      showPaginationBottom={true}
      columns={[
        {
          Header: "Name",
          accessor: "name",
        },
        {
          Header: "Location",
          accessor: "location",
        },
        {
          Header: "Filters",
          accessor: "filters",
        },
        {
          Header: "Last Executed",
          accessor: "last_executed",
        },
        {
          Header: "Next Execution",
          accessor: "next_execution",
        },
        {
          Header: "",
          accessor: "actions",
        },
      ]}
      data={data.map((data) => {
        return {
          location: data.location.address,
          name: data.name,
          filters: (
            <React.Fragment>
              <Chip
                variant="outlined"
                size="small"
                label={`Distance: ${data.distance} miles`}
              />
              <Chip
                variant="outlined"
                size="small"
                label={`Min Price: ${data.min_price}`}
              />
              <Chip
                variant="outlined"
                size="small"
                label={`Max Price: ${
                  data.max_price === 0 ? "Unlimited" : data.max_price
                }`}
              />
              <Chip
                variant="outlined"
                size="small"
                label={`Property Types: ${data.property_types
                  .map((item) => item.property_type.name)
                  .join(",")}`}
              />

              <Chip
                variant="outlined"
                size="small"
                label={`Listing Types: ${data.listing_types
                  .map((item) => item.listing_type.name)
                  .join(",")}`}
              />
              <Chip
                variant="outlined"
                size="small"
                label={`Frequency: ${data.frequency.name}`}
              />
              {data &&
                data.filter_us_counties &&
                data.filter_us_counties.length > 0 && (
                  <React.Fragment>
                    {data.filter_us_counties.map((item) => {
                      return (
                        <Chip
                          key={item.us_county.name + item.us_county.state}
                          variant="outlined"
                          size="small"
                          label={`${item.us_county.name} - ${item.us_county.state}`}
                        />
                      );
                    })}
                  </React.Fragment>
                )}
            </React.Fragment>
          ),
          last_executed:
            data.last_generated_filter_listing_result &&
            data.last_generated_filter_listing_result.created_at
              ? data.last_generated_filter_listing_result.created_at
              : "N/A",
          next_execution: data.next_execution_at
            ? data.next_execution_at
            : "N/A",
          actions: (
            <React.Fragment>
              <Button
                color="primary"
                onClick={() => {
                  addActivity({
                    variables: {
                      action: "show_generated_list",
                      description: `show generated list ${data.id}`,
                    },
                  });
                  window.location.href = `https://new.movingshortly.com/dashboard/generatedlists`;
                }}
              >
                Show Generated Lists
              </Button>
              {deletingFilter ? (
                <CircularProgress />
              ) : (
                <Button
                  color="secondary"
                  onClick={() => {
                    addActivity({
                      variables: {
                        action: "delete_list",
                        description: `deleted list ${data.id}`,
                      },
                    });
                    deleteFilter(data.id);
                  }}
                >
                  Delete
                </Button>
              )}
            </React.Fragment>
          ),
        };
      })}
    />
  );
};

export default Table;
