import React, { useState } from "react";
import ReactTable from "react-table";
import { Link } from "react-router-dom";
import { Button } from "@material-ui/core";
import SoundPlayer from "./SoundPlayer";
import {
  useHistory,
  Switch,
  Route,
  useParams,
  useRouteMatch
} from "react-router-dom";

const Table = ({ data }) => {
  const [soundRequestedIds, setSoundRequestedIds] = useState([]);
  const history = useHistory();
  let match = useRouteMatch("/admin/numbers/:id");
  console.log(match);
  return (
    <ReactTable
      defaultPageSize={data && data.length > 50 ? 50 : data.length}
      showPaginationBottom={data && data.length > 50 ? true : false}
      columns={[
        {
          Header: "From",
          accessor: "from"
        },
        {
          Header: "To",
          accessor: "to"
        },
        {
          Header: "Date",
          accessor: "created_at"
        },
        {
          Header: "Recording",
          accessor: "recording"
        }
      ]}
      data={data.map(data => {
        return {
          from: data.from,
          to: data.to,
          created_at: data.created_at,
          recording: (
            <Switch>
              <Route exact path={`${match.url}/play/${data.call_sid}`}>
                <SoundPlayer />
              </Route>
              <Route path="/admin/numbers/:id">
                <Button
                  onClick={() => {
                    history.push(`${match.url}/play/${data.call_sid}`);
                  }}
                >
                  Play Recording
                </Button>
              </Route>
            </Switch>
          )
        };
      })}
    />
  );
};

export default Table;
