import React from "react";
import { useQuery } from "@apollo/react-hooks";
import { GET_ALERTS } from "../../graphql/queries/alert";
import Alert from "./Alert";

const Alerts = () => {
  const { data } = useQuery(GET_ALERTS);
  const { alerts } = data;
  if (alerts && alerts.length > 0) {
    return alerts.map(({ id, type, message, title }) => {
      return <Alert id={id} title={title} type={type} message={message} />;
    });
  }
  return <div />;
};

export default Alerts;
