import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Paper,
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableHead,
  Chip,
} from "@material-ui/core";
import { useQuery } from "@apollo/react-hooks";
import { GET_LISTS } from "graphql/queries/superuser/lists";
import { useParams } from "react-router-dom";

const useStyles = makeStyles({
  paper: {
    margin: "1em",
    padding: "1em",
  },
  table: {
    minWidth: 700,
  },
});

export default function SuperUser() {
  const classes = useStyles();
  const { id } = useParams();
  const { data } = useQuery(GET_LISTS, {
    variables: { organization_id: id },
  });
  if (data && data.filter && data.filter.length > 0) {
    return (
      <Paper className={classes.paper}>
        <Table className={classes.table} aria-label="spanning table">
          <TableHead>
            <TableRow>
              <TableCell align="left">Name</TableCell>
              <TableCell align="left">Location</TableCell>
              <TableCell align="left">Filters</TableCell>
              <TableCell align="left">Last Executed</TableCell>
              <TableCell align="left">Next Execution</TableCell>
              <TableCell align="left">Generated Files</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.filter.map((row) => (
              <TableRow key={row.id}>
                <TableCell align="left">{row.name}</TableCell>
                <TableCell align="left">{row.location.address}</TableCell>
                <TableCell align="left">
                  <React.Fragment>
                    <Chip
                      variant="outlined"
                      size="small"
                      label={`Distance: ${row.distance} miles`}
                    />
                    <Chip
                      variant="outlined"
                      size="small"
                      label={`Min Price: ${row.min_price}`}
                    />
                    <Chip
                      variant="outlined"
                      size="small"
                      label={`Max Price: ${
                        row.max_price === 0 ? "Unlimited" : row.max_price
                      }`}
                    />
                    <Chip
                      variant="outlined"
                      size="small"
                      label={`Property Types: ${row.property_types
                        .map((item) => item.property_type.name)
                        .join(",")}`}
                    />

                    <Chip
                      variant="outlined"
                      size="small"
                      label={`Listing Types: ${row.listing_types
                        .map((item) => item.listing_type.name)
                        .join(",")}`}
                    />
                    <Chip
                      variant="outlined"
                      size="small"
                      label={`Frequency: ${row.frequency.name}`}
                    />
                    {row &&
                      row.filter_us_counties &&
                      row.filter_us_counties.length > 0 && (
                        <React.Fragment>
                          {row.filter_us_counties.map((item) => {
                            return (
                              <Chip
                                key={item.us_county.name + item.us_county.state}
                                variant="outlined"
                                size="small"
                                label={`${item.us_county.name} - ${item.us_county.state}`}
                              />
                            );
                          })}
                        </React.Fragment>
                      )}
                  </React.Fragment>
                </TableCell>
                <TableCell align="left">
                  {row.last_generated_filter_listing_result &&
                  row.last_generated_filter_listing_result.created_at
                    ? row.last_generated_filter_listing_result.created_at
                    : "N/A"}
                </TableCell>
                <TableCell align="left">
                  {row.next_execution_at ? row.next_execution_at : "N/A"}
                </TableCell>
                <TableCell align="left">
                  <a href="https://new.movingshortly.com/dashboard/generated">
                    Generated Files
                  </a>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>
    );
  }
  return <div>No data available</div>;
}
