import gql from "graphql-tag";

export const GET_LOCATIONS = gql`
  query {
    location {
      address
      address_components
      plan {
        distance_in_miles
      }
    }
  }
`;
