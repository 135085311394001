import gql from "graphql-tag";

export const GET_NUMBERS = gql`
  query {
    number {
      id
      number
      forward_to
      stats {
        day
        week
        month
      }
    }
  }
`;

export const SEARCH_AVAILABLE_NUMBERS = gql`
  query($city: String!, $state: String!) {
    search_available_numbers(city: $city, state: $state) {
      e164
      international_number_formatted
      national_number_formatted
    }
  }
`;

export const GET_NUMBER_ACTIVITY = gql`
  query activity($id: uuid!) {
    number_activity(
      where: { number_id: { _eq: $id } }
      order_by: { created_at: desc }
    ) {
      from
      to
      created_at
      call_sid
    }
  }
`;

export const GET_RECORDING_URL = gql`
  query getRecordingUrl($id: String!) {
    call_recording_url(sid: $id)
  }
`;
