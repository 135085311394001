import gql from "graphql-tag";

export const GET_ALL = gql`
  query {
    organization(order_by: { created_at: desc }) {
      id
      name
      created_at
      users {
        id
        name
        phone_number
        email
      }
      locations {
        address
      }
    }
  }
`;
