import React, { CSSProperties, HTMLAttributes, useState } from "react";
import Select from "react-select";
import {
  createStyles,
  emphasize,
  makeStyles,
  useTheme,
  Theme
} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import TextField, { BaseTextFieldProps } from "@material-ui/core/TextField";
import Paper from "@material-ui/core/Paper";
import MenuItem from "@material-ui/core/MenuItem";
import { ValueContainerProps } from "react-select/src/components/containers";
import { ControlProps } from "react-select/src/components/Control";
import { MenuProps, NoticeProps } from "react-select/src/components/Menu";
import { OptionProps } from "react-select/src/components/Option";
import { PlaceholderProps } from "react-select/src/components/Placeholder";
import { SingleValueProps } from "react-select/src/components/SingleValue";
import { Omit } from "@material-ui/types";
import AwesomeDebouncePromise from "awesome-debounce-promise";

declare var google: any;

// variable that will hold googleMaps Autocomplete object
var autocomplete: any = undefined;

interface OptionType {
  label: string;
  value: string;
}

const getPlaceDetails = async (placeId: any) => {
  return new Promise((resolve, reject) => {
    const service = new google.maps.places.PlacesService(
      document.createElement("div")
    );
    service.getDetails(
      {
        placeId,
        fields: [
          "name",
          "formatted_address",
          "place_id",
          "geometry",
          "address_component",
          "type"
        ]
      },
      (place: any, status: any) => {
        resolve(place);
        if (!place) {
          reject(status);
        }
      }
    );
  });
};

const searchAutocomplete = (text: any) => {
  if (!autocomplete) {
    autocomplete = new google.maps.places.AutocompleteService();
  }
  return new Promise((resolve, reject) => {
    if (!autocomplete) {
      reject("Google Places Autocomplete not initialized");
    }
    autocomplete.getPlacePredictions(
      {
        componentRestrictions: { country: ["us", "ca"] },
        types: ["address"],
        input: text
      },
      (data: any, status: any) => {
        if (data) {
          resolve(data);
        } else {
          reject(status);
        }
      }
    );
  });
};

const searchAutocompleteDebounced = AwesomeDebouncePromise(
  searchAutocomplete,
  300
);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1
    },
    input: {
      display: "flex",
      height: "auto",
      padding: ".3em"
    },
    valueContainer: {
      display: "flex",
      flexWrap: "wrap",
      flex: 1,
      alignItems: "center",
      overflow: "hidden"
    },
    chip: {
      margin: 0,
      backgroundColor: "white"
    },
    chipFocused: {
      backgroundColor: emphasize(
        theme.palette.type === "light"
          ? theme.palette.grey[300]
          : theme.palette.grey[700],
        0.08
      )
    },
    noOptionsMessage: {
      padding: theme.spacing(1, 2)
    },
    singleValue: {
      fontSize: 14
    },
    placeholder: {
      position: "absolute",
      left: 2,
      bottom: 6,
      fontSize: 14
    },
    paper: {
      backgroundColor: "white",
      opacity: 1,
      position: "absolute",
      zIndex: 99,
      marginTop: 0,
      left: 0,
      right: 0
    },
    divider: {
      height: theme.spacing(0)
    }
  })
);

const NoOptionsMessage = (props: NoticeProps<OptionType>) => {
  return (
    <Typography
      color="textSecondary"
      className={props.selectProps.classes.noOptionsMessage}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
};

type InputComponentProps = Pick<BaseTextFieldProps, "inputRef"> &
  HTMLAttributes<HTMLDivElement>;

const inputComponent = ({ inputRef, ...props }: InputComponentProps) => {
  return <div ref={inputRef} {...props} />;
};

const Control = (props: ControlProps<OptionType>) => {
  const {
    children,
    innerProps,
    innerRef,
    selectProps: { classes, TextFieldProps }
  } = props;

  return (
    <TextField
      fullWidth
      variant="outlined"
      margin="normal"
      InputProps={{
        inputComponent,
        inputProps: {
          className: classes.input,
          ref: innerRef,
          children,
          ...innerProps
        }
      }}
      {...TextFieldProps}
    />
  );
};

const Option = (props: OptionProps<OptionType>) => {
  return (
    <MenuItem
      ref={props.innerRef}
      selected={props.isFocused}
      component="div"
      style={{
        fontWeight: props.isSelected ? 500 : 400
      }}
      {...props.innerProps}
    >
      {props.children}
    </MenuItem>
  );
};

type MuiPlaceholderProps = Omit<PlaceholderProps<OptionType>, "innerProps"> &
  Partial<Pick<PlaceholderProps<OptionType>, "innerProps">>;
const Placeholder = (props: MuiPlaceholderProps) => {
  const { selectProps, innerProps = {}, children } = props;
  return (
    <Typography
      color="textSecondary"
      className={selectProps.classes.placeholder}
      {...innerProps}
    >
      {children}
    </Typography>
  );
};

const SingleValue = (props: SingleValueProps<OptionType>) => {
  return (
    <Typography
      className={props.selectProps.classes.singleValue}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
};

const ValueContainer = (props: ValueContainerProps<OptionType>) => {
  return (
    <div className={props.selectProps.classes.valueContainer}>
      {props.children}
    </div>
  );
};

const Menu = (props: MenuProps<OptionType>) => {
  return (
    <Paper
      square
      className={props.selectProps.classes.paper}
      {...props.innerProps}
    >
      {props.children}
    </Paper>
  );
};

const components = {
  Control,
  Menu,
  NoOptionsMessage,
  Option,
  Placeholder,
  SingleValue,
  ValueContainer
};

interface AutocompleteProps {
  id?: string | undefined;
  label?: string;
  placeholder?: string;
  error: boolean;
  helperText: string;
  onOptionClick: (value: any) => void;
  onFocus: () => void;
  // TODO: fix the value type before was { label: string; value: string }
  value?: any;
}
const PlacesAutoComplete = (props: AutocompleteProps) => {
  const [searchStr, setSearchStr] = useState("");
  const [options, setOptions] = useState([]);
  const classes = useStyles();
  const theme = useTheme();

  const handleInputChange = (address: string, action: any) => {
    // handle typing
    if (action && action.action && action.action === "input-change") {
      searchAutocompleteDebounced(address).then((result: any) => {
        if (result && result.length > 0) {
          setOptions(
            result.map((item: any) => {
              return {
                label: item.description,
                value: item.place_id
              };
            })
          );
        } else {
          if (options && options.length > 0) {
            setOptions([]);
          }
        }
      });
    }
  };
  const handleOptionClick = async (value: any) => {
    if (value && value.value) {
      getPlaceDetails(value.value).then(placeDetails =>
        props.onOptionClick(placeDetails)
      );
    }
    setSearchStr(value);
  };

  const handleInputFocus = () => {
    setSearchStr("");
    props.onFocus();
  };

  const selectStyles = {
    input: (base: CSSProperties) => ({
      ...base,
      color: theme.palette.text.primary,
      "& input": {
        font: "inherit"
      }
    })
  };
  return (
    <Select
      classes={classes}
      styles={selectStyles}
      inputId={props.id ? props.id : "select"}
      TextFieldProps={{
        label: props.label,
        error: props.error,
        helperText: props.helperText,
        InputLabelProps: {
          htmlFor: props.id ? props.id : "select",
          shrink: true
        }
      }}
      isClearable={true}
      placeholder=""
      options={options}
      components={components}
      value={
        props.value && props.value.hasOwnProperty("label")
          ? props.value
          : searchStr
      }
      onChange={handleOptionClick}
      onInputChange={handleInputChange}
      onFocus={handleInputFocus}
    />
  );
};

export default PlacesAutoComplete;
