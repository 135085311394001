import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Paper,
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableHead,
  Button
} from "@material-ui/core";
import { useQuery } from "@apollo/react-hooks";
import { GET_ALL } from "graphql/queries/superuser/all";
import { Link } from "react-router-dom";

const useStyles = makeStyles({
  paper: {
    margin: "1em",
    padding: "1em"
  },
  table: {
    minWidth: 700
  }
});

export default function SuperUser() {
  const classes = useStyles();
  const { data } = useQuery(GET_ALL);
  if (data && data.organization && data.organization.length > 0) {
    return (
      <Paper className={classes.paper}>
        <Table className={classes.table} aria-label="spanning table">
          <TableHead>
            <TableRow>
              <TableCell align="left">Organization</TableCell>
              <TableCell align="left">Name</TableCell>
              <TableCell align="left">Phone</TableCell>
              <TableCell align="left">Email</TableCell>
              <TableCell align="left">Signup Date</TableCell>
              <TableCell align="left">Address</TableCell>
              <TableCell align="left">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.organization.map(row => (
              <TableRow key={row.id}>
                <TableCell align="left">{row.name}</TableCell>
                <TableCell align="left">{row.users[0].name}</TableCell>
                <TableCell align="left">{row.users[0].phone_number}</TableCell>
                <TableCell align="left">{row.users[0].email}</TableCell>
                <TableCell align="left">{row.locations[0].address}</TableCell>
                <TableCell align="left">{row.created_at}</TableCell>
                <TableCell align="left">
                  <Link to={`/superuser/filters/${row.id}`} component={Button}>
                    Filters
                  </Link>
                  <Link
                    to={`/superuser/communications/${row.id}`}
                    component={Button}
                  >
                    Communications
                  </Link>
                  <Link to={`/superuser/actions/${row.id}`} component={Button}>
                    Activities
                  </Link>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>
    );
  }
  return <div />;
}
