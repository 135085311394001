import React from "react";
import { useQuery } from "@apollo/react-hooks";
import ReactAudioPlayer from "react-audio-player";
import { CircularProgress } from "@material-ui/core";
import { GET_RECORDING_URL } from "graphql/queries/numbers";
import { useRouteMatch } from "react-router-dom";

const SoundPlayer = () => {
  let match = useRouteMatch("/admin/numbers/:number/play/:id");
  console.log(match);
  const { data, loading, error } = useQuery(GET_RECORDING_URL, {
    variables: {
      id: match.params.id
    }
  });
  if (loading) {
    return <CircularProgress />;
  }
  if (data && data.call_recording_url) {
    return <ReactAudioPlayer src={data.call_recording_url} autoPlay controls />;
  }
  return "Call recording not available";
};

export default SoundPlayer;
