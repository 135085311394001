import React from "react";
import { Formik, FormikHelpers } from "formik";

const FormikNoType: any = Formik;

type FormProps = {
  children: any;
  initialValues: any;
  validationSchema: any;
  onSubmit: (values: any, formikActions: FormikHelpers<any>) => void;
};
const LoginForm: any = ({
  children,
  initialValues,
  validationSchema,
  onSubmit
}: FormProps) => (
  <FormikNoType
    validateOnBlur={false}
    initialValues={initialValues}
    validationSchema={validationSchema}
    onSubmit={async (values, formikActions) => {
      onSubmit(values, formikActions);
    }}
  >
    {(props: any) => {
      return children(props);
    }}
  </FormikNoType>
);

export default LoginForm;
