import gql from "graphql-tag";

export const ADD_FILTER = gql`
  mutation($data: [filter_insert_input!]!) {
    insert_filter(objects: $data) {
      affected_rows
    }
  }
`;

export const DELETE_FILTER = gql`
  mutation($id: uuid!) {
    delete_filter(where: { id: { _eq: $id } }) {
      affected_rows
    }
  }
`;
