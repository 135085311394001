import gql from "graphql-tag";

export const GET_PROPERTY_STATS = gql`
  query {
    is_location_new {
      is_new
    }
    stats_listing_canada {
      day
      week
      month
    }
    stats_listing_us {
      day
      week
      month
    }
    stats_number_activity {
      day
      month
      week
      number {
        number
      }
    }
  }
`;
