import React from "react";
// react plugin for creating charts
import ChartistGraph from "react-chartist";
// react plugin for creating vector maps
import { VectorMap } from "react-jvectormap";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import Icon from "@material-ui/core/Icon";

// @material-ui/icons
// import ContentCopy from "@material-ui/icons/ContentCopy";
import Store from "@material-ui/icons/Store";
// import InfoOutline from "@material-ui/icons/InfoOutline";
import Warning from "@material-ui/icons/Warning";
import DateRange from "@material-ui/icons/DateRange";
import LocalOffer from "@material-ui/icons/LocalOffer";
import Update from "@material-ui/icons/Update";
import ArrowUpward from "@material-ui/icons/ArrowUpward";
import AccessTime from "@material-ui/icons/AccessTime";
import Refresh from "@material-ui/icons/Refresh";
import Edit from "@material-ui/icons/Edit";
import Place from "@material-ui/icons/Place";
import ArtTrack from "@material-ui/icons/ArtTrack";
import Language from "@material-ui/icons/Language";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Table from "components/Table/Table.js";
import Button from "components/CustomButtons/Button.js";
import Danger from "components/Typography/Danger.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import Welcome from "components/Welcome/Welcome";
import { CircularProgress } from "@material-ui/core";

import {
  dailySalesChart,
  emailsSubscriptionChart,
  completedTasksChart
} from "variables/charts";

import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";

import priceImage1 from "assets/img/card-2.jpeg";
import priceImage2 from "assets/img/card-3.jpeg";
import priceImage3 from "assets/img/card-1.jpeg";
import { useQuery } from "@apollo/react-hooks";
import { GET_PROPERTY_STATS } from "graphql/queries/stats";

const us_flag = require("assets/img/flags/US.png");
const de_flag = require("assets/img/flags/DE.png");
const au_flag = require("assets/img/flags/AU.png");
const gb_flag = require("assets/img/flags/GB.png");
const ro_flag = require("assets/img/flags/RO.png");
const br_flag = require("assets/img/flags/BR.png");

var mapData = {
  AU: 760,
  BR: 550,
  CA: 120,
  DE: 1300,
  FR: 540,
  GB: 690,
  GE: 200,
  IN: 200,
  RO: 600,
  RU: 300,
  US: 2920
};

const useStyles = makeStyles(styles);

export default function Dashboard() {
  const classes = useStyles();
  const { loading, data, error } = useQuery(GET_PROPERTY_STATS);

  if (data && data.is_location_new && data.is_location_new[0].is_new) {
    return <Welcome />;
  }
  return (
    <div>
      <GridContainer>
        {((data && data.stats_listing_canada) ||
          (data && data.stats_listing_us)) && (
          <React.Fragment>
            <GridContainer>
              <GridItem xs={12}>
                <h4>Properties</h4>
              </GridItem>
            </GridContainer>
            <GridItem xs={12} sm={6} md={6} lg={4}>
              <Card>
                <CardHeader color="warning" stats icon>
                  <CardIcon color="warning">
                    <Icon>content_copy</Icon>
                  </CardIcon>
                  <p className={classes.cardCategory}>For Sale</p>
                  <h3 className={classes.cardTitle}>
                    {data.stats_listing_canada && data.stats_listing_canada[0]
                      ? data.stats_listing_canada[0].day
                      : data.stats_listing_us && data.stats_listing_us[0]
                      ? data.stats_listing_us[0].day
                      : 0}{" "}
                    <small>properties</small>
                  </h3>
                </CardHeader>
                <CardFooter stats>
                  <div className={classes.stats}>
                    <DateRange />
                    Last 24 hours
                  </div>
                </CardFooter>
              </Card>
            </GridItem>
            <GridItem xs={12} sm={6} md={6} lg={4}>
              <Card>
                <CardHeader color="success" stats icon>
                  <CardIcon color="success">
                    <Store />
                  </CardIcon>
                  <p className={classes.cardCategory}>For Sale</p>
                  <h3 className={classes.cardTitle}>
                    {data.stats_listing_canada && data.stats_listing_canada[0]
                      ? data.stats_listing_canada[0].week
                      : data.stats_listing_us && data.stats_listing_us[0]
                      ? data.stats_listing_us[0].week
                      : 0}{" "}
                    <small>properties</small>
                  </h3>
                </CardHeader>
                <CardFooter stats>
                  <div className={classes.stats}>
                    <DateRange />
                    Last Week
                  </div>
                </CardFooter>
              </Card>
            </GridItem>
            <GridItem xs={12} sm={6} md={6} lg={4}>
              <Card>
                <CardHeader color="danger" stats icon>
                  <CardIcon color="danger">
                    <Icon>info_outline</Icon>
                  </CardIcon>
                  <p className={classes.cardCategory}>For Sale</p>
                  <h3 className={classes.cardTitle}>
                    {data.stats_listing_canada && data.stats_listing_canada[0]
                      ? data.stats_listing_canada[0].month
                      : data.stats_listing_us && data.stats_listing_us[0]
                      ? data.stats_listing_us[0].month
                      : 0}{" "}
                    <small>properties</small>
                  </h3>
                </CardHeader>
                <CardFooter stats>
                  <div className={classes.stats}>
                    <DateRange />
                    Last Month
                  </div>
                </CardFooter>
              </Card>
            </GridItem>
          </React.Fragment>
        )}
        {data &&
          data.stats_number_activity &&
          data.stats_number_activity.length > 0 && (
            <React.Fragment>
              <GridContainer>
                <GridItem xs={12}>
                  <h4>Tracking Numbers</h4>
                </GridItem>
              </GridContainer>
              {data.stats_number_activity.map(item => {
                return (
                  <React.Fragment>
                    <GridItem xs={12} sm={6} md={6} lg={4}>
                      <Card>
                        <CardHeader color="warning" stats icon>
                          <CardIcon color="warning">
                            <Icon>content_copy</Icon>
                          </CardIcon>
                          <p className={classes.cardCategory}>
                            {item.number.number}
                          </p>
                          <h3 className={classes.cardTitle}>
                            {item.day ? item.day : 0} <small>calls</small>
                          </h3>
                        </CardHeader>
                        <CardFooter stats>
                          <div className={classes.stats}>
                            <DateRange />
                            Last 24 hours
                          </div>
                        </CardFooter>
                      </Card>
                    </GridItem>
                    <GridItem xs={12} sm={6} md={6} lg={4}>
                      <Card>
                        <CardHeader color="success" stats icon>
                          <CardIcon color="success">
                            <Store />
                          </CardIcon>
                          <p className={classes.cardCategory}>
                            {item.number.number}
                          </p>
                          <h3 className={classes.cardTitle}>
                            {item.week ? item.week : 0} <small>calls</small>
                          </h3>
                        </CardHeader>
                        <CardFooter stats>
                          <div className={classes.stats}>
                            <DateRange />
                            Last Week
                          </div>
                        </CardFooter>
                      </Card>
                    </GridItem>
                    <GridItem xs={12} sm={6} md={6} lg={4}>
                      <Card>
                        <CardHeader color="danger" stats icon>
                          <CardIcon color="danger">
                            <Icon>info_outline</Icon>
                          </CardIcon>
                          <p className={classes.cardCategory}>
                            {item.number.number}
                          </p>
                          <h3 className={classes.cardTitle}>
                            {item.month ? item.month : 0} <small>calls</small>
                          </h3>
                        </CardHeader>
                        <CardFooter stats>
                          <div className={classes.stats}>
                            <DateRange />
                            Last Month
                          </div>
                        </CardFooter>
                      </Card>
                    </GridItem>
                  </React.Fragment>
                );
              })}
            </React.Fragment>
          )}
      </GridContainer>
    </div>
  );
}
