import { InMemoryCache } from "apollo-cache-inmemory";
import { ApolloClient } from "apollo-client";
import { ApolloLink, split } from "apollo-link";
import { HttpLink } from "apollo-link-http";
import { WebSocketLink } from "apollo-link-ws";
import { getMainDefinition } from "apollo-utilities";
import { onError } from "apollo-link-error";
import { graphqlUrl, subscriptionUrl } from "../config/config";
import authToken from "./token";
import alertResolver from "./resolvers/alert";

// Instance of a cache
const cache = new InMemoryCache();

// pass authentication header when exists
const authMiddleware = new ApolloLink((operation, forward: any) => {
  const token = authToken.get();
  if (token) {
    operation.setContext({
      headers: {
        authorization: `Bearer ${token}`
      }
    });
  }
  return forward(operation);
});

// Set up subscription
const wsLink = new WebSocketLink({
  uri: subscriptionUrl,
  options: {
    reconnect: true,
    connectionParams: () => {
      if (authToken.get()) {
        return {
          headers: {
            authorization: `Bearer ${authToken.get()}`
          }
        };
      }
      return {
        headers: {}
      };
    }
  }
});

// Set up http link
const httpLink = new HttpLink({
  uri: graphqlUrl
});

interface Definintion {
  kind: string;
  operation?: string;
}

// using the ability to split links, you can send data to each link
// depending on what kind of operation is being sent
const link = split(
  // split based on operation type
  ({ query }) => {
    const { kind, operation }: Definintion = getMainDefinition(query);
    return kind === "OperationDefinition" && operation === "subscription";
  },
  wsLink,
  httpLink
);

// log errors to Apollo Client Cache
const logErrors = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    console.log(graphQLErrors);
  }
  if (networkError) {
    console.log(networkError);
  }
});

// Apollo
export const client = new ApolloClient({
  link: ApolloLink.from([logErrors, authMiddleware, link]),
  cache,
  resolvers: {
    Mutation: {
      ...alertResolver
    }
  }
});

const initialState = {
  alerts: []
};

cache.writeData({
  data: initialState
});

client.onResetStore(() => {
  return new Promise((resolve, reject) => {
    cache.writeData({ data: initialState });
    resolve();
  });
});
